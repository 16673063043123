<template>
  <div class="church-list template-1">
    <header class="page-header container mx-lg header">
      <h1>{{ translations.tcIndividualHistory }}</h1>
      <QuickJump
        :individualKeyProp="this.userSelectedIndividualKey"
        @individual_changed="individual_changed()"
        :i18n="translations.components"
      ></QuickJump>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage"></security-banner>
    <div v-if="allowPage">
      <!-- <camp-selector
        @camp_changed="oftb_changed()"
        :showCamp="false"
        :tooltip="translations.tcToolTip"
        :i18n="translations.components"
      ></camp-selector> -->

      <section class="list container">
        <b-button variant="primary" class="mr-3" @click="handleBackClick()">
          {{ translations.tcBack }}
        </b-button>
      </section>
      <data-table
        v-if="translations.components"
        :fields="dataTableFields"
        :items="items"
        :hiddenItems="hiddenItems"
        :includeAddButton="false"
        :searchTerm="`conversationsTerm`"
        :showExportBtn="false"
        :i18n="translations.components"
      ></data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableConversations.vue'
import QuickJump from '@/components/quick-jump/QuickJumpConversationsIndividuals.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'individual-history',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      dataTableFields: [],
      hiddenItems: {
        display: false,
      },
      allowPage: true
    }
  },
  computed: {
    ...mapGetters({
      churchPresentersByCampAsCSVUrl: 'churchMinistry/datalist1CSVUrl',
      demograph: 'user/demograph',
      educational_unit_history: 'conversations/educational_unit_history',
      individual_history: 'conversations/individual_history',
      officerToolbarSelected: 'user/officerToolbarSelected',
      selected_educational_unit_key: 'conversations/selected_educational_unit_key',
      userLanguageKey: 'user/userLanguageKey',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    items() {
      return this.individual_history.map((el) => {
        return {
          cvs_key: el.cvs_key,
          mtg_key: el.mtg_key,
          mtg_start_date: el.mtg_start_date,
          meeting_type: el.meeting_type,
          mtg_title: el.mtg_title,
          educationalunit_name: el.educationalunit_name ? el.educationalunit_name : 'Not Entered',
          instructor_name: el.instructor_name ? el.instructor_name : 'Not Entered',
          location_org_name: el.location_org_name,
          citystate: el.citystate,
          attendee_count: el.attendee_count
        }
      })
    },
  },
  methods: {
    ...mapActions({
      clearIndividualHistory: 'conversations/clearIndividualHistory',
      loadChurchPresenterHistoryReportUrls: 'churchMinistry/loadChurchPresenterHistoryReportUrls', /// TODO: FIX THIS FOR THE educationalunitS
      //loadEducationalUnits: 'conversations/loadEducationalUnits',
      loadConversationsHistory: 'conversations/loadConversationsHistory',
      loadConversationsIndividualHistory: 'conversations/loadConversationsIndividualHistory',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedEducationalUnitKey: 'conversations/setSelectedEducationalUnitKey',
    }),
    async individual_changed() {
      await this.clearIndividualHistory()
      await this.page_load()
    },
    async handleBackClick() {
      // route to the previous page in history
      this.$router.go(-1)
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        const payload = {
          org_key: this.officerToolbarSelected.country_state || this.demograph.state_key,
          lang_key: this.userLanguageKey
        }
        //await this.loadEducationalUnits(payload)
        // if (this.selected_educational_unit_key !== null) {
        //   this.setSelectedIndividualKey(this.selected_educational_unit_key)
        //   this.setSelectedEducationalUnitKey(null)
        // }
        if (this.userSelectedIndividualKey !== null) {
          await this.loadConversationsIndividualHistory({ ind_key: this.userSelectedIndividualKey })
        }
        await this.loadChurchPresenterHistoryReportUrls()
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        { key: 'mtg_start_date', label: `${this.translations.tcMtgStartDate}`, sortable: true, class: 'non-wrap'},
        {
          key: 'meeting_type',
          label: `${this.translations.tcMeetingType}`,
          sortable: true
        },
        { key: 'mtg_title', label: `${this.translations.tcMtgTitle}`, sortable: true },
        { key: 'educationalunit_name', label: `${this.translations.tcEducationalUnitName}`, sortable: false, class: 'non-wrap' },
        { key: 'instructor_name', label: `${this.translations.tcInstructorName}`, sortable: false , class: 'non-wrap'},
        { key: 'location_org_name', label: `${this.translations.tcLocation}`, sortable: false },
        { key: 'citystate', label: `${this.translations.tcCityState}`, sortable: false },
        { key: 'attendee_count', label: `${this.translations.tcAttendees}`, sortable: false }
      ]
    },
    async oftb_changed(evt) {
      await this.clearIndividualHistory()
      await this.setSelectedIndividualKey(null)
      this.page_load()
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('data-table-conversations', 'camp-select', 'quick-jump-educational-unit', 'security-banner'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
        this.page_load()
      })
    } catch (e) {
      console.error(e)
    } finally {
      //this.setLoadingStatus(false)
    }
  },
  mounted() {},
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    QuickJump: QuickJump,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.church-list {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}
</style>

